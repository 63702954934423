<template>
	<div id="main">
		<div v-if="customerEmail && orderId && products">
			<stripe-checkout
				ref="upgradeRef"
				:pk="pk"
				mode="payment"
				:line-items="products"
				:success-url="`https://custombrandingpro.com/brand_kit/thank_you/${orderId}`"
				:cancel-url="`https://custombrandingpro.com/brand_kit/cancel/${orderId}`"
				:client-reference-id="orderId"
				:customer-email="customerEmail"
				submit-type="pay"
			/>
		</div>

		<div class="modal" v-if="planModal">
			<div class="modal-inner">
				<div class="modal-top-bar">
					<button @click="planModal = false">&times;</button>
				</div>
				<div class="row modal-body">
					<div class="col-xs-12">
						<h2>Choose Plan</h2>
					</div>
					<div class="col-md-4 col-sm-12 col-xs-12 plan-option-area">
						<p>
							<strong>Basic Artwork</strong>
						</p>
						<button
							@click="selectPlan(0, tmpCurrentRouteUploadId)"
							v-text="`${price[0]} (Express)`"
						></button>

						<br />
						<br />

						<button
							@click="selectPlan(1, tmpCurrentRouteUploadId)"
							v-text="price[1]"
						></button>
					</div>
					<div class="col-md-4 col-sm-12 col-xs-12 plan-option-area">
						<p>
							<strong>Launch ready</strong>
						</p>
						<button
							@click="selectPlan(2, tmpCurrentRouteUploadId)"
							v-text="`${price[2]} (Express)`"
						></button>

						<br />
						<br />

						<button
							@click="selectPlan(3, tmpCurrentRouteUploadId)"
							v-text="price[3]"
						></button>
					</div>
					<div class="col-md-4 col-sm-12 col-xs-12 plan-option-area">
						<p>
							<strong>Full house</strong>
						</p>
						<button
							@click="selectPlan(4, tmpCurrentRouteUploadId)"
							v-text="`${price[4]} (Express)`"
						></button>

						<br />
						<br />

						<button
							@click="selectPlan(5, tmpCurrentRouteUploadId)"
							v-text="price[5]"
						></button>
					</div>
					<div class="col-xs-12" v-if="readyToUpgrade">
						<button
							@click="
								launchStripe(
									selectedPrice,
									tmpCurrentRouteUploadId
								)
							"
						>
							Upgrade!
						</button>
					</div>
					<div class="col-xs-12">
						<p>
							You can compare the different plans
							<router-link to="pricing" target="_blank"
								>here</router-link
							>
							.
						</p>
					</div>
				</div>
			</div>
		</div>

		<div id="main-inner" class="row">
			<div id="header" class="col-xs-12">
				<h1>Brand kit</h1>
				<p v-text="'For order: ' + orderId"></p>
			</div>

			<div id="order" class="col-xs-12">
				<div id="brand-kits" class="row">
					<div
						class="col-md-4 col-xs-12"
						@click="maybeUploadRoute(0)"
					>
						<h1>Route 1</h1>
						<img
							:src="loadRouteImage(0)"
							alt="Brand-kit 1"
							:class="selection === 0 ? 'active' : ''"
						/>
					</div>
					<div
						class="col-md-4 col-xs-12"
						@click="maybeUploadRoute(1)"
					>
						<h1>Route 2</h1>
						<img
							:src="loadRouteImage(1)"
							alt="Brand-kit 2"
							:class="selection === 1 ? 'active' : ''"
						/>
					</div>
					<div
						class="col-md-4 col-xs-12"
						@click="maybeUploadRoute(2)"
					>
						<h1>Route 3</h1>
						<img
							:src="loadRouteImage(2)"
							alt="Brand-kit 3"
							:class="selection === 2 ? 'active' : ''"
						/>
					</div>
					<div class="col-xs-12">
						<p>
							Feel free to use these routes for your own use as
							you wish
						</p>
					</div>
				</div>

				<div id="tools" v-if="selection !== null">
					<div id="tools-inner" class="row">
						<button @click="planModal = true">
							Choose & Upgrade
						</button>
					</div>
				</div>

				<div id="uploader" v-if="tmpCurrentRouteUploadId !== null">
					<div class="row">
						<div class="col-md-6 col-sm-12 col-xs-12">
							<h3
								v-text="
									'Upload design route ' +
									(tmpCurrentRouteUploadId + 1)
								"
							></h3>
							<File
								accepts=".jpg, .jpeg, .png, .svg, .pdf"
								:is-required="false"
								unique-id="0"
								@upload="
									uploadRoute(tmpCurrentRouteUploadId, $event)
								"
							/>
						</div>
						<div class="col-md-6 col-sm-12 col-xs-12">
							<button
								v-if="
									brandKitRoutes.length >=
									tmpCurrentRouteUploadId + 1
								"
								@click="deleteRoute(tmpCurrentRouteUploadId)"
							>
								Delete this route
							</button>
						</div>
					</div>
				</div>

				<div
					id="notify-client"
					v-if="isAdmin && brandKitRoutes.length >= 3"
				>
					<button @click="notify()">Notify client</button>
					<p>Let the client know that their brand kit is ready</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		fireGet,
		fireStorageDelete,
		fireStorageGetAll,
		fireUploadFormFiles,
		fireAsyncSignIn
	} from '../db';
	import File from '../components/form/formComponents/File';
	import { validateFileUpload } from '../files';
	import api from '../api';
	import { StripeCheckout } from '@vue-stripe/vue-stripe';

	export default {
		name: 'BrandKit',
		components: {
			File,
			StripeCheckout
		},
		props: {
			orderId: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				pk: '',
				data: {},
				brandKitRoutes: [],
				tmpCurrentRouteUploadId: null,
				selection: null,
				selectedPrice: null,
				price: [
					'800€',
					'399€',
					'4.000€',
					'1.990€',
					'12.000€',
					'6.990€'
				],
				planModal: false,
				readyToUpgrade: false
			};
		},
		mounted() {
			fireAsyncSignIn()
				.then(() => {
					this.mountedFunc();
				})
				.catch(e => {
					console.log(`fireAsyncSignIn Error ${e.message}`);
				});
		},
		methods: {
			mountedFunc() {
				this.pk = process.env.VUE_APP_PK;

				if (localStorage.getItem('token')) {
					this.$store.commit(
						'setToken',
						localStorage.getItem('token')
					);
				}

				if (this.$store.state.token) {
					api.post(
						'/admin/test-token',
						{},
						{
							params: {
								token: this.$store.state.token
							}
						}
					).catch(e => {
						console.log(`Normal error token test: ${e.message}`);
						this.$store.commit('setToken', '');
						localStorage.setItem('token', '');
					});
				}

				fireGet(this.orderId).then(data => {
					if (data) {
						this.data = JSON.parse(data.raw);
						this.customerEmail = this.data?.you?.email;
					} else {
						this.data = {};
					}
				});
				this.getRoutes();
			},
			selectPlan(price, routeIndex) {
				this.selectedPrice = price;
				localStorage.setItem('brandKitToUpgrade', routeIndex);
				this.readyToUpgrade = true;

				this.launchStripe(this.selectedPrice, routeIndex);
			},
			launchStripe() {
				this.planModal = false;
				this.$refs.upgradeRef.redirectToCheckout();
				this.readyToUpgrade = false;
			},
			notify() {
				api.post(
					'/brand-kit/notify',
					{
						email: this.data.you.email
					},
					{
						params: {
							token: this.$store.state.token
						}
					}
				);
			},
			getRoutes() {
				fireStorageGetAll('brand-kit')
					.then(images => {
						images.forEach(image => {
							if (image.fullPath.includes(`${this.orderId}_`)) {
								image.getDownloadURL().then(url => {
									this.brandKitRoutes.push(url);
								});
							}
						});
					})
					.catch(e => {
						console.error(`getAll Storage Error ${e}`);
					});
			},
			loadRouteImage(id) {
				let resImg = require('../assets/brand-kit-empty.png');
				if (this.brandKitRoutes.length >= id + 1) {
					this.brandKitRoutes.forEach(img => {
						if (img.includes(`${this.orderId}_${id}_`)) {
							resImg = img;
						}
					});
				}

				return resImg;
			},
			maybeUploadRoute(id) {
				if (this.$store.state.token) {
					this.tmpCurrentRouteUploadId =
						this.tmpCurrentRouteUploadId === id ? null : id;
				} else {
					this.tmpCurrentRouteUploadId = null;
					this.selection = this.selection === id ? null : id;
				}
			},
			uploadRoute(id, e) {
				if (validateFileUpload(e)) {
					fireUploadFormFiles(
						e,
						`${this.orderId}_${this.tmpCurrentRouteUploadId}`,
						'brand-kit',
						() => {
							window.location = String(window.location);
						}
					);
				} else {
					alert('file size too large');
				}

				this.tmpCurrentRouteUploadId = null;
			},
			deleteRoute() {
				fireStorageGetAll('brand-kit').then(images => {
					images.forEach(image => {
						if (
							image.fullPath.includes(
								`${this.orderId}_${this.tmpCurrentRouteUploadId}`
							)
						) {
							fireStorageDelete(image);
							window.location = String(window.location);
						}
					});
				});
			}
		},
		computed: {
			isAdmin() {
				return this.$store.state.token.length > 0;
			},
			products() {
				const prices = [
					process.env.VUE_APP_PRICE_EXPRESS_BASIC_ARTWORK,
					process.env.VUE_APP_PRICE_BASIC_ARTWORK,
					process.env.VUE_APP_PRICE_EXPRESS_LAUNCH_READY,
					process.env.VUE_APP_PRICE_LAUNCH_READY,
					process.env.VUE_APP_PRICE_EXPRESS_FULL_HOUSE,
					process.env.VUE_APP_PRICE_FULL_HOUSE
				];

				if (this.selectedPrice) {
					return [
						{
							price: prices[this.selectedPrice],
							quantity: 1
						}
					];
				}

				return null;
			},
			customerEmail() {
				return this.data?.you?.email;
			}
		}
	};
</script>

<style scoped>
	#main {
		background-color: var(--light-grey);
		padding: 20px;
		margin-top: 10vh;
	}

	#main-inner {
		max-width: 80%;
		margin: 0 auto;
	}

	#header,
	#order {
		width: 90%;
		margin: 0 auto;
		text-align: center;
		background-color: var(--white);
		margin-bottom: 20px;
		padding: 10px;
	}

	#header h1 {
		color: var(--black);
		text-align: center;
	}

	#header p {
		text-align: center;
		font-size: 0.8rem;
	}

	#order {
		width: 80%;
	}

	#brand-kits {
		background-color: var(--white);
		width: 100%;
		margin: 0 auto;
		padding-top: 30px;
	}

	#brand-kits img {
		max-width: 80%;
		border-bottom: 8px solid transparent;
		box-shadow: 4px 4px 4px 2px #eee;
		border: 2px solid var(--light-grey);
	}

	#brand-kits img:hover {
		cursor: pointer;
		border-bottom-color: var(--accent);
	}

	#brand-kits .active {
		outline: 3px solid var(--accent);
		border-bottom-color: var(--accent);
	}

	#tools {
		text-align: center;
		width: 100%;
		display: flex;
		align-items: center;
	}

	#tools-inner {
		margin: 0 auto;
		width: fit-content;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.modal {
		width: 100%;
		height: 100vh;
		position: fixed;
		z-index: 99;
		text-align: center;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.05);
	}

	.modal-top-bar {
		width: 100%;
		text-align: right;
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.modal-top-bar button {
		background: none;
		color: var(--black) !important;
		font-size: 2.5rem;
	}

	.modal-inner {
		margin: 0 auto;
		background-color: var(--white);
		width: 60%;
		box-shadow: 2px 2px 4px var(--light-grey);
		padding: 30px;
		position: relative;
	}

	.plan-option-area {
		padding-top: 10px;
		padding-bottom: 10px;
	}
</style>
